(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("videojs"), require("WaveSurfer"));
	else if(typeof define === 'function' && define.amd)
		define("VideojsWavesurfer", ["videojs", "WaveSurfer"], factory);
	else if(typeof exports === 'object')
		exports["VideojsWavesurfer"] = factory(require("videojs"), require("WaveSurfer"));
	else
		root["VideojsWavesurfer"] = factory(root["videojs"], root["WaveSurfer"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__7__, __WEBPACK_EXTERNAL_MODULE__8__) {
return 